export const PATHS_local = {
  // Get test data
  GETTESTDATA: process.env.REACT_APP_HOME_URL + "api/GetTestData",

  // Get test scenarios
  GETTESTSCENARIOS: process.env.REACT_APP_HOME_URL + "api/GetTestScenarios",

  // Start test scenario
  STARTTESTSCENARIO: process.env.REACT_APP_HOME_URL + "api/StartTestScenario",

  // Get scenario execution result
  GETSCENARIOEXECUTIONRESULT:
    process.env.REACT_APP_HOME_URL + "api/GetScenarioExecutionResult",

  // Websocket
  BLOCKLIST: process.env.REACT_APP_HOME_URL + "api/blocklist",

  // Get Service Group - DONE
  GETSERVICEGROUP:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/servicemanagement/GetServiceGroup",

  // Get Activation Jobs By Vehicle ID - DONE
  GETACTIVATIONJOBSBYVEHICLEID:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/servicemanagement/GetActivationJobsByVehicleId",

  // Get Vehicle by UuId - DONE
  GETVEHICLEBYUUID:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetVehicleByUuid",

  // Get Vehicle Claims - DONE
  GETVEHICLECLAIMS:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetVehicleClaims",

  // Get All Vehicle Users - DONE
  GETALLVEHICLEUSERS:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetAllVehicleUsers",

  // Get All Vehicle Service Claims
  GETALLVEHICLESERVICECLAIMS:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetAllVehicleServiceClaims",

  // Get Vehicle Claims Manifest
  GETVEHICLECLAIMSMANIFEST:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetVehicleClaimsManifest",

  // Get Default Service Vehicle User Claims Of Primary User
  GETUSERCLAIMSPRIMARYUSER:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/servicemanagement/GetDefaultServiceVehicleUserClaimsOfPrimaryUser",

  // Get Connection STatus
  GETCONNECTIONSTATUS:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/commandmanagement/GetConnectionStatusCache",

  // Get Connection status live
  GETCONNECTIONSTATUSLIVE:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetConnectionState",

  // Get Vehicle Current State
  GETVEHICLECURRENTSTATE:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/commandmanagement/GetVehicleCurrentState",

  // Get Vehicle Stages And Environments
  GETVEHICLESTAGESANDENVIRONMENTS:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/vds_v1/GetVehicleStagesAndEnvironments",

  // Get Vehicle Uuid
  GETVEHICLEVINBYUUID:
    process.env.REACT_APP_HOME_URL +
    "api/services/vwac/vehiclemanagement/GetVehicle",

  // Get ODP User List
  GETODPUSERLIST:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/usermanagementUsers_v2/dynamic/GetVehicleUsers",

  // Get User From Pseudo User
  GETUSERFROMPSEUDOUSER:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/users_v1_pseudodata/GetUserFromPseudoUser",

  // Get Pseudo Vin From Vin
  GETPSEUDOVINFROMVIN:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/vehicles_v1_pseudodata/GetPseudoVinFromVin",

  // Get Pseudo Vin From VIN list from CSV:
  GETPVINFROMVINLISTFROMCSV:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/vehicles_v1_pseudodata/GetPseudoVinFromVinList",

  // Get Vin From Pseudo Vin
  GETVINFROMPSEUDOVIN:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/vehicles_v1_pseudodata/GetVinFromPseudoVin",

  // Get Pseudo User From User
  GETPSEUDOUSERFROMUSER:
    process.env.REACT_APP_HOME_URL +
    "api/services/msg/users_v1_pseudodata/GetPseudoUserFromUser",

  // Upload CSV file - CCP Generator
  GENERATECCP: process.env.REACT_APP_HOME_URL + "api/ccp-generator/generateCCP",

  // Get Certificate Bundles
  GETCERTIFICATEBUNDLES:
    process.env.REACT_APP_HOME_URL + "api/tprt-vkms-ha/GetDlcBundles",

  // Get Manifest History
  GETMANIFESTHISTORY:
    process.env.REACT_APP_HOME_URL + "api/services/cube/GetManifestHistory",

  // Get Manifest
  GETMANIFEST: process.env.REACT_APP_HOME_URL + "api/services/cube/GetManifest",

  // Get Pending Manifest
  GETPENDINGMANIFEST:
    process.env.REACT_APP_HOME_URL + "api/services/cube/GetPendingManifest",
};

const regexPattern = /(.*\.cloud)\/.*$/;
const baseUrl = window.location.href.replace(regexPattern, "$1");

export const PATHS = {
  // Get test data
  GETTESTDATA: baseUrl + "/api/GetTestData",

  // Get test scenarios
  GETTESTSCENARIOS: baseUrl + "/api/GetTestScenarios",

  // Start test scenario
  STARTTESTSCENARIO: baseUrl + "/api/StartTestScenario",

  // Get scenario execution result
  GETSCENARIOEXECUTIONRESULT: baseUrl + "/api/GetScenarioExecutionResult",

  // Websocket
  BLOCKLIST: baseUrl + "/api/blocklist",

  // Get Service Group
  GETSERVICEGROUP:
    baseUrl + "/api/services/vwac/servicemanagement/GetServiceGroup",

  // Get Activation Jobs By Vehicle ID
  GETACTIVATIONJOBSBYVEHICLEID:
    baseUrl +
    "/api/services/vwac/servicemanagement/GetActivationJobsByVehicleId",

  // Get Vehicle by UuId
  GETVEHICLEBYUUID:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetVehicleByUuid",

  // Get Vehicle Claims
  GETVEHICLECLAIMS:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetVehicleClaims",

  // Get All Vehicle Users
  GETALLVEHICLEUSERS:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetAllVehicleUsers",

  // Get All Vehicle Service Claims
  GETALLVEHICLESERVICECLAIMS:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetAllVehicleServiceClaims",

  // Get Vehicle Claims Manifest
  GETVEHICLECLAIMSMANIFEST:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetVehicleClaimsManifest",

  // Get Default Service Vehicle User Claims Of Primary User
  GETUSERCLAIMSPRIMARYUSER:
    baseUrl +
    "/api/services/vwac/servicemanagement/GetDefaultServiceVehicleUserClaimsOfPrimaryUser",

  // Get Vehicle Current State
  GETVEHICLECURRENTSTATE:
    baseUrl + "/api/services/vwac/commandmanagement/GetVehicleCurrentState",

  // Get Connection Status
  GETCONNECTIONSTATUS:
    baseUrl + "/api/services/vwac/commandmanagement/GetConnectionStatusCache",

  // Get Connection status live
  GETCONNECTIONSTATUSLIVE:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetConnectionState",

  // Get Vehicle Stages And Environments
  GETVEHICLESTAGESANDENVIRONMENTS:
    baseUrl + "/api/services/msg/vds_v1/GetVehicleStagesAndEnvironments",

  // Get vehicle by Uuid
  GETVEHICLEVINBYUUID:
    baseUrl + "/api/services/vwac/vehiclemanagement/GetVehicle",

  // Get ODP User List
  GETODPUSERLIST:
    baseUrl +
    "/api/services/msg/usermanagementUsers_v2/dynamic/GetVehicleUsers",

  // Get Pseudo Vin From Vin
  GETPSEUDOVINFROMVIN:
    baseUrl + "/api/services/msg/vehicles_v1_pseudodata/GetPseudoVinFromVin",

  // Get Pseudo Vin From VIN list from CSV:
  GETPVINFROMVINLISTFROMCSV:
    baseUrl +
    "/api/services/msg/vehicles_v1_pseudodata/GetPseudoVinFromVinList",

  // Get Vin From Pseudo Vin
  GETVINFROMPSEUDOVIN:
    baseUrl + "/api/services/msg/vehicles_v1_pseudodata/GetVinFromPseudoVin",

  // Get User From Pseudo User
  GETUSERFROMPSEUDOUSER:
    baseUrl + "/api/services/msg/users_v1_pseudodata/GetUserFromPseudoUser",

  // Get Pseudo User From User
  GETPSEUDOUSERFROMUSER:
    baseUrl + "/api/services/msg/users_v1_pseudodata/GetPseudoUserFromUser",

  // Upload CSV file - CCP Generator
  GENERATECCP: baseUrl + "/api/ccp-generator/generateCCP",

  // Get Certificate Bundles
  GETCERTIFICATEBUNDLES: baseUrl + "/api/tprt-vkms-ha/GetDlcBundles",

  // Get Manifest History
  GETMANIFESTHISTORY: baseUrl + "/api/services/cube/GetManifestHistory",

  // Get Manifest
  GETMANIFEST: baseUrl + "/api/services/cube/GetManifest",

  // Get Pending Manifest
  GETPENDINGMANIFEST: baseUrl + "/api/services/cube/GetPendingManifest",
};
