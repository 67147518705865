import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "#utils/api.js";
import { logError } from "#utils/utils.js";
import { errorHandler } from "#components/Error Handler/errorHandler.js";

const initialState = {
  manifest: {},
  manifestHistory: {},
  pendingManifest: {},
  pendingManifestValue: "",
  isLoadingDataManifest: false,
  isLoadingDataManifestHistory: false,
  isLoadingDataPendingManifestManifest: false,
};

// Slice
const cubeSlice = createSlice({
  name: "cube",
  initialState,
  reducers: {
    setManifest: (state, action) => {
      state.manifest = action.payload;
    },
    setManifestHistory: (state, action) => {
      state.manifestHistory = action.payload;
    },
    setPendingManifest: (state, action) => {
      state.pendingManifest = action.payload;
    },
    setPendingManifestValue: (state, action) => {
      state.pendingManifestValue = action.payload;
    },
    setIsLoadingDataManifest: (state, action) => {
      state.isLoadingDataManifest = action.payload;
    },
    setIsLoadingDataManifestHistory: (state, action) => {
      state.isLoadingDataManifestHistory = action.payload;
    },
    setIsLoadingDataPendingManifest: (state, action) => {
      state.isLoadingDataPendingManifest = action.payload;
    },
  },
});

// Selectors

export const selectManifest = (state) => state.cube.manifest;
export const selectManifestHistory = (state) => state.cube.manifestHistory;
export const selectPendingManifest = (state) => state.cube.pendingManifest;
export const selectPendingManifestValue = (state) =>
  state.cube.pendingManifestValue;
export const selectIsLoadingDataManifest = (state) =>
  state.cube.isLoadingDataManifest;
export const selectIsLoadingDataManifestHistory = (state) =>
  state.cube.isLoadingDataManifestHistory;
export const selectIsLoadingDataPendingManifest = (state) =>
  state.cube.isLoadingDataPendingManifest;

// Actions

export const {
  setIsLoadingDataManifest,
  setIsLoadingDataManifestHistory,
  setIsLoadingDataPendingManifest,
  setManifest,
  setManifestHistory,
  setPendingManifest,
  setPendingManifestValue,
} = cubeSlice.actions;

//Getter

// getManifest
export const getManifest = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataManifest(true));
  postWithAuth("GETMANIFEST", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setManifest(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataManifest(false));
    });
};

// getManifestHistory
export const getManifestHistory = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataManifestHistory(true));
  postWithAuth("GETMANIFESTHISTORY", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setManifestHistory(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataManifestHistory(false));
    });
};

// getPendingManifest
// export const getPendingManifest = (environment, region, vin) => (dispatch) => {
//   dispatch(setIsLoadingDataPendingManifest(true));
//   postWithAuth("GETPENDINGMANIFEST", {}, { environment, region, vin })
//     .then((response) => {
//       if (response !== null) {
//         dispatch(setPendingManifest(response));
//       } else {
//         console.log(response);
//       }
//     })
//     .catch((error) => {
//       logError(error);
//       errorHandler(error, dispatch);
//     })
//     .finally(() => {
//       dispatch(setIsLoadingDataPendingManifest(false));
//     });
// };

// getPendingManifest when 404 occurs
export const getPendingManifest = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataPendingManifest(true));

  postWithAuth("GETPENDINGMANIFEST", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setPendingManifest(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      // Check if the error has a response with the ObservedResult
      if (error?.response?.data) {
        const responseData = error.response.data;

        try {
          // Check if ObservedResult is present directly in the response data
          const observedResult = responseData?.ObservedResult;

          // Dispatch the ObservedResult if found
          if (observedResult) {
            dispatch(setPendingManifestValue(observedResult));
            console.log("MANIFEST VALUE_____:_____", observedResult);
          } else if (responseData.RequestResult?.ResponseContent) {
            // Try to parse ResponseContent if ObservedResult is not directly found
            const parsedContent = JSON.parse(
              responseData.RequestResult.ResponseContent
            );
            const parsedObservedResult = parsedContent?.ObservedResult;

            if (parsedObservedResult) {
              dispatch(setPendingManifestValue(parsedObservedResult));
              console.log("MANIFEST VALUE_____:_____", parsedObservedResult);
            } else {
              console.log("ObservedResult not found in parsed content");
            }
          } else {
            console.log("ObservedResult not found in error response");
          }
        } catch (parseError) {
          logError(parseError);
        }
      } else {
        logError(error);
        errorHandler(error, dispatch);
      }
    })
    .finally(() => {
      dispatch(setIsLoadingDataPendingManifest(false));
    });
};
export default cubeSlice.reducer;
