import { configureStore } from "@reduxjs/toolkit";
import infoSnackBarSlice from "../components/InfoSnackbar/infoSnackbarSlice";
import snackBarSlice from "../components/Snackbar/snackbarSlice";
import projectSlice from "../pages/Homepage/projectSlice";
import resultsSlice from "../pages/Results Report Page/resultsSlice";
import progressSlice from "../pages/RunningTests Page/progressSlice";
import testsSlice from "../pages/TestData Selection/testsSlice";
import appSlice from "../appSlice";
// import websocketSlice from "../pages/websocket/websocketSlice";
import backendInfoSlice from "../pages/backendDataInfoPage/backendInfoSlice";
import serviceGroupSlice from "../pages/backendDataInfoPage/serviceGroupSlice";
import vehicleClaimsSlice from "../pages/backendDataInfoPage/vehicleClaimsSlice";
import vehicleUuIDSlice from "../pages/backendDataInfoPage/vehicleUuIDSlice";
import vehicleUsersSlice from "../pages/backendDataInfoPage/vehicleUsersSlice";
import activationJobsSlice from "../pages/backendDataInfoPage/activationJobsSlice";
import allVehicleServiceClaimsSlice from "../pages/backendDataInfoPage/allVehicleServiceClaimsSlice";
import primaryUserClaimsSlice from "../pages/backendDataInfoPage/primaryUserClaimsSlice";
import vehicleCurrentStateSlice from "../pages/backendDataInfoPage/vehicleCurrentStateSlice";
import connectionStatusCacheSlice from "../pages/backendDataInfoPage/connectionStatusCacheSlice";
import connectionStatusLiveSlice from "../pages/backendDataInfoPage/connectionStatusLiveSlice";
import vdsCheckSlice from "../pages/backendDataInfoPage/vdsCheckSlice";
import ccpGeneratorSlice from "../pages/TOOL-ccpGenerator/ccpGeneratorSlice";
import odpUserListSlice from "../pages/backendDataInfoPage/odpUserList/odpUserListSlice";
import vinByUuIDSlice from "../pages/TOOL-vinByUuID/vinByUuIDSlice";
import pseudonymizerSlice from "../pages/TOOL-pseudonymizer/pseudonymizerSlice";
import themesongSlice from "#components/themesong/themesongSlice.js";
import certificateBundlesSlice from "#pages/TOOL-certificateBundles/certificateBundlesSlice.js";
import cubeSlice from "#pages/backendDataInfoPage/cube/cubeSlice.js";

export const store = configureStore({
  reducer: {
    progress: progressSlice,
    projects: projectSlice,
    results: resultsSlice,
    snackBar: snackBarSlice,
    tests: testsSlice,
    infoSnackBar: infoSnackBarSlice,
    app: appSlice,
    // websocket: websocketSlice
    backendData: backendInfoSlice,
    serviceGroup: serviceGroupSlice,
    vehicleClaims: vehicleClaimsSlice,
    vehicleUuID: vehicleUuIDSlice,
    vehicleUsers: vehicleUsersSlice,
    activationJobs: activationJobsSlice,
    allVehicleServiceClaims: allVehicleServiceClaimsSlice,
    primaryUserClaims: primaryUserClaimsSlice,
    vehicleCurrentState: vehicleCurrentStateSlice,
    connectionStatusCache: connectionStatusCacheSlice,
    connectionStatusLive: connectionStatusLiveSlice,
    vdsCheck: vdsCheckSlice,
    ccpGenerator: ccpGeneratorSlice,
    odpUserList: odpUserListSlice,
    vinByUuID: vinByUuIDSlice,
    pseudonymizer: pseudonymizerSlice,
    themesong: themesongSlice,
    certificateBundles: certificateBundlesSlice,
    cube: cubeSlice,
  },
});
